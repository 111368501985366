:root {
  --primary: #000000;
  --primary-text: #fff;
}

body {
  font-family: 'Share Tech Mono', monospace;
  color: var(--primary-text);
  background: var(--primary);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
